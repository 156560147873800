import { useNavigate } from '@remix-run/react';
import React, { useMemo, useState } from 'react';
import { $path } from 'remix-routes';

import {
  type DtoGamePack,
  type DtoProduct,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type GamePack } from '../../types/game';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { GamePackUtils } from '../Game/GamePack/utils';
import { ProductUtils } from '../Product/utils';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';

const CONTACT_US_PRICE_ID = 'contact-us';

function useHeadcountOptions(pack: DtoGamePack | GamePack) {
  return useMemo(() => {
    const priceTable = GamePackUtils.ActivePrices(pack);
    if (!priceTable || priceTable.length === 0) return [];
    const prices = priceTable.map((price, i) => {
      if (i === 0) {
        return {
          ...price,
          minPlayers: pack.playerRange.min,
        };
      }
      return {
        ...price,
        minPlayers: priceTable[i - 1].maxPlayers + 1,
      };
    });
    prices.push({
      id: CONTACT_US_PRICE_ID,
      amount: 0,
      minPlayers: prices[prices.length - 1].maxPlayers + 1,
      maxPlayers: Infinity,
      archived: false,
    });
    return prices;
  }, [pack]);
}

export function InlineHeadcountEditor(props: {
  pack: DtoGamePack | GamePack;
  headcount: number;
  onHeadcountChange: (headcount: number) => void;
}) {
  const { pack, headcount, onHeadcountChange } = props;

  const options = useHeadcountOptions(pack);
  const selectedOption = useMemo(() => {
    return options.find(
      (option) =>
        headcount >= option.minPlayers && headcount <= option.maxPlayers
    );
  }, [headcount, options]);

  const [editingHeadcount, setEditingHeadcount] = useState<number | null>(null);
  const [editingHeadcountError, setEditingHeadcountError] =
    useState<React.ReactNode | null>(null);

  const handleEditingHeadcountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingHeadcount(e.currentTarget.valueAsNumber);
    setEditingHeadcountError(null);
  };

  const handleSaveHeadcountChange = useLiveCallback(() => {
    if (!editingHeadcount) return;

    const nextOption = options.find(
      (option) =>
        editingHeadcount >= option.minPlayers &&
        editingHeadcount <= option.maxPlayers
    );

    if (!nextOption) {
      setEditingHeadcountError(
        <>
          This experience requires a minimum of {options[0].minPlayers} players.
        </>
      );
    } else if (nextOption.id === CONTACT_US_PRICE_ID) {
      setEditingHeadcountError(
        <>
          For a group of {nextOption.minPlayers} or more, please{' '}
          <a
            href='mailto:support@lunapark.com'
            className='text-primary underline'
          >
            contact us
          </a>
          .
        </>
      );
    } else {
      setEditingHeadcount(null);
      setEditingHeadcountError(null);
      onHeadcountChange(editingHeadcount);
    }
  });

  if (editingHeadcount === null) {
    return (
      <div className='mt-2 flex items-center gap-3'>
        <div className='w-[fit-content] h-6 bg-lp-gray-003 rounded px-2 text-sms flex justify-center items-center'>
          {selectedOption && selectedOption.id !== CONTACT_US_PRICE_ID ? (
            <>
              {selectedOption.minPlayers}–{selectedOption.maxPlayers} people
            </>
          ) : (
            <>{headcount} people</>
          )}
        </div>
        <button
          type='button'
          onClick={() => setEditingHeadcount(headcount)}
          className='btn text-sms text-primary'
        >
          Edit
        </button>
      </div>
    );
  }

  return (
    <div className='mt-2'>
      <div className='flex items-center gap-3'>
        <input
          className='field rounded w-20 h-6 mb-0 text-center'
          type='number'
          defaultValue={editingHeadcount}
          onChange={handleEditingHeadcountChange}
        />
        <button
          type='button'
          onClick={handleSaveHeadcountChange}
          className='btn text-sms text-primary'
        >
          Save
        </button>
      </div>
      {editingHeadcountError && (
        <div className='mt-1 text-3xs'>{editingHeadcountError}</div>
      )}
    </div>
  );
}

export function OTPCheckoutHeadcount(props: {
  pack: DtoGamePack;
  product: DtoProduct;
}) {
  const { pack, product } = props;

  const navigate = useNavigate();

  const [headcount, setHeadcount] = useState<number | undefined>(undefined);

  const packPrice = headcount
    ? GamePackUtils.FindOneTimePrice(pack, headcount)
    : null;
  const productPrice = headcount
    ? ProductUtils.FindPrice(product, headcount)
    : null;

  const isLargeGroup = !!headcount && (!packPrice || !productPrice);
  const amount =
    packPrice && productPrice
      ? Math.min(packPrice.amount, productPrice.amount)
      : null;

  const handleContinue = () => {
    if (!headcount) return;

    const params = new URLSearchParams(window.location.search);
    params.set('headcount', headcount.toString());
    navigate($path('/checkout', params));
  };

  return (
    <OTPCheckoutLayout pack={pack} progress={10} checkAccessible>
      <div className='w-full h-full flex flex-col items-center justify-center gap-3'>
        <div className='w-96 bg-modal rounded-2.5xl px-10 py-7.5 gap-5 flex flex-col items-center border border-secondary'>
          <div className='text-center text-tertiary text-xl font-bold'>
            How many are you expecting?
          </div>

          <div className='w-full bg-layer-002 border border-secondary rounded-xl pl-4 p-3 flex items-center gap-2'>
            <div className='flex-1 flex flex-col gap-1 text-white'>
              <div className='font-bold'>Headcount</div>
              <div className='text-2xs font-light'>
                You can Increase the team size later!
              </div>
            </div>

            <div className='flex-none w-34 flex flex-col items-end gap-1'>
              <input
                name='headcount'
                type='number'
                value={headcount || ''}
                onChange={(e) => setHeadcount(e.currentTarget.valueAsNumber)}
                className={`
                     w-20 text-xl font-bold text-center m-0 p-0 field
                  `}
                placeholder={'0'}
              />
            </div>
          </div>

          <div className='w-full h-[170px] bg-layer-002 rounded-xl border border-secondary overflow-hidden'>
            <img
              className='w-full h-full object-cover'
              alt=''
              src={MediaUtils.PickMediaUrl(fromMediaDTO(pack.cover)) ?? ''}
            />
          </div>

          <div className='w-full flex flex-col justify-center gap-3'>
            <div className='w-full flex items-baseline justify-between border-b border-secondary pb-3 mb-1 text-xl font-bold'>
              <div
                className={`text-white transition-opacity ${
                  !amount ? 'opacity-50' : 'opacity-100'
                }`}
              >
                Instant Quote
              </div>

              {amount ? (
                <>
                  <div>${amount} USD</div>
                </>
              ) : (
                <></>
              )}
            </div>

            <button
              type='button'
              className='w-full h-15 btn-primary'
              onClick={handleContinue}
              disabled={!headcount}
            >
              {isLargeGroup ? 'Let’s Chat!' : 'Continue Booking'}
            </button>
          </div>
        </div>
        <div className='w-96 h-15 px-3 text-center'>
          {isLargeGroup && (
            <div className='text-white text-base leading-tight font-bold'>
              To ensure your large group has a great experience, we’ll connect
              you with an account manager who can help plan your event.
            </div>
          )}
        </div>
      </div>
    </OTPCheckoutLayout>
  );
}
